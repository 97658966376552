export default {
  title: '功能介紹',
  categories: {
    'tracking-the-expense': {
      name: '記帳',
      files: {
        'introduction': '介紹',
        'multi-currency-support': '跨幣種記錄',
        'fee-and-discount': '手續費與折扣',
        'transaction-reminder': '提醒入帳',
        'postpone-transaction': '延後入帳',
        'recurring-event': '週期性收支',
        'installment': '分期付款',
        'transfer': '轉帳',
        'receivables': '應收款項',
        'payables': '應付款項',
        'refund': '退款',
        'complete-gesture': '完成記帳手勢',
        'collection': '拆帳',
        'continue-recording': '連續記帳',
        'template': '範本',
      },
    },

    'account': {
      name: '帳戶',
      files: {
        'introduction': '介紹',
        'credit-account': '信用帳戶',
        'credit-sharing': '信用額度共用',
        'statement-interval': '結算區間',
        'auto-deposit': '自動儲值',
        'balance-included': '納入總餘額',
        'receivables-payables': '應收應付款項',
        'statement-mode': '對帳模式',
        'adjust-balance': '餘額調整',
        'statement-payment': '信用卡繳款',
        'combine-account': '信用卡帳單合併',
        'statement-installment': '信用卡帳單分期',
        'bonus-rewards': '紅利回饋',
      },
    },

    'project': {
      name: '專案',
      files: {
        'introduction': '介紹',
        'statistic-project': '統計專案',
        'budget': '預算',
      },
    },

    'report': {
      name: '報表',
      files: {
        introduction: '介紹',
      },
    },

    'notifications': {
      name: '通知中心',
      files: {
        introduction: '介紹',
      },
    },

    'search': {
      name: '搜尋',
      files: {
        'introduction': '介紹',
        'batch-edit-and-delete': '批次修改刪除',
      },
    },

    'backup-restore': {
      name: '備份＆還原',
      files: {
        introduction: '介紹',
      },
    },

    'import-export': {
      name: '匯入＆匯出',
      files: {
        import: '匯入',
        export: '匯出',
      },
    },

    'e-invoice': {
      name: '電子發票（台灣限定）',
      files: {
        'introduction': '介紹',
        'carrier': '手機條碼載具',
        'invoice-link': '發票歸帳',
        'winning-list': '自動對獎',
      },
    },
  },
};
