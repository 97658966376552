import _ from 'lodash';
import Vue from 'vue';
import VueI18n, { LocaleMessages } from 'vue-i18n';
import { LocationService } from '@/services/location';
// tslint:disable-next-line: no-var-requires
const ElementLocal = require('element-ui/lib/locale').default;

Vue.use(VueI18n);

export default () => {
  const I18N_LOCALE = LocationService.getUserLanguage() || process.env.VUE_APP_I18N_LOCALE || 'tw';
  const I18N_FALLBACK_LOCALE = process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'tw';

  const i18n = new VueI18n({
    locale: I18N_LOCALE,
    fallbackLocale: I18N_FALLBACK_LOCALE,
    messages: _.reduce<string, LocaleMessages>([
      I18N_LOCALE,
      I18N_FALLBACK_LOCALE,
    ], (obj, lang) => {
      obj[lang] = require(`@/locales/${lang}`).default;
      return obj;
    }, {}),
  });

  ElementLocal.i18n((key: string, value: any) => i18n.t(key, value));

  return i18n;
};
