export default {
  title: 'FEATURES',
  categories: {
    'tracking-the-expense': {
      name: 'Tracking the Expenses',
      files: {
        'introduction': 'Tracking the Expenses',
        'multi-currency-support': 'Multi-currency Support',
        'fee-and-discount': 'Fee & Discount',
        'transaction-reminder': 'Transaction Reminder',
        'postpone-transaction': 'Postpone Transaction',
        'recurring-event': 'Recurring Event',
        'installment': 'Installment',
        'transfer': 'Transfer',
        'receivables': 'Receivables',
        'payables': 'Payables',
        'refund': 'Refund',
        'complete-gesture': 'Swipe Down to Record the Expense',
        'collection': 'Split Payment',
        'continue-recording': 'Continous Tracking',
        'template': 'Template',
      },
    },

    'account': {
      name: 'Accounts',
      files: {
        'introduction': 'Accounts',
        'credit-account': 'Credit Account',
        'credit-sharing': 'Credit Limit Sharing',
        'statement-interval': 'Statement Interval',
        'auto-deposit': 'Auto-Deposit',
        'balance-included': 'Including in the balance',
        'receivables-payables': 'Receivables and Payables',
        'statement-mode': 'Statement Mode',
        'adjust-balance': 'Balance Adjustment',
        'statement-payment': 'Credit Card Payment',
        'bonus-rewards': 'Credit Card Rewards',
      },
    },

    'project': {
      name: 'Projects',
      files: {
        'introduction': 'Projects',
        'statistic-project': 'Statistic Project',
        'budget': 'Budget',
      },
    },

    'report': {
      name: 'Analysis Reports',
      files: {
        introduction: 'Analysis Reports',
      },
    },

    'notifications': {
      name: 'Notifications',
      files: {
        introduction: 'Notifications',
      },
    },

    'search': {
      name: 'Search',
      files: {
        'introduction': 'Search',
        'batch-edit-and-delete': 'Batch Edit / Delete',
      },
    },

    'backup-restore': {
      name: 'Backup & Restore',
      files: {
        introduction: 'Backup & Restore',
      },
    },

    'import-export': {
      name: 'Import & Export',
      files: {
        import: 'Import',
        export: 'Export',
      },
    },
  },
};
