import Vue from 'vue';
import { Button, Row, Col, Dropdown, DropdownItem, DropdownMenu, Tooltip } from 'element-ui';

Vue.use(Button);
Vue.use(Row);
Vue.use(Col);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Tooltip);
