export default {
  'title': 'MOZE - 最美記帳',
  'nav': {
    home: '首頁',
    features: '功能介紹',
    pricing: '定價方案',
    faq: '常見問題',
  },
  'section-1': {
    'title': '最美記帳',
    'paragraph': 'MOZE 是一款個人記帳軟體，擁有美麗的介面並非常注重用戶體驗，致力於將記帳變成你的生活習慣',
    'btn-free-download': '免費下載',
    'btn-coming-soon': '即將上線',
  },
  'section-2': {
    title: '記帳，是理財的第一步',
    paragraph: '每個人都應該去嘗試看看，了解你自己平常的花費習慣，找出為什麼身上的錢總是留不住的原因',
    features: {
      grasp: {
        title: '清楚掌握收支',
        desc: '記錄每一筆開銷，瞭解你的用錢習慣',
      },
      planning: {
        title: '做好預算規劃',
        desc: '找出固定支出和浮動支出，更有效的規劃財務和掌控花費進度',
      },
      economize: {
        title: '節省不必要的開支',
        desc: '消費前先問問自己這是需要還是想要，養成良好的消費習慣',
      },
      goal: {
        title: '提高目標意識',
        desc: '擁有健康的理財概念，才是擁有財富的基礎',
      },
    },
  },
  'section-3': {
    'title': '先前有過記帳經驗嗎？',
    'paragraph': '別擔心，不管你是新手還是老手，MOZE 提供了完整的記帳流程和進階功能來幫助你得到更完善的記帳體驗',
    'section-newbie': {
      title: '剛接觸記帳的新手',
      features: [
        {
          title: '為你的消費分門別類',
          desc: 'MOZE 內建了超過 100 種常見的分類圖示，你只要選擇適合的類別（像是飲料、晚餐、停車費），填上金額和選定支付帳戶，即可簡單完成一筆記帳。',
        },
        {
          title: '追蹤你的資產',
          desc: '將你的各張信用卡、不同銀行存款、現金、外幣、禮卷等各式各樣的資產和負債，個別建立帳戶去追蹤收支明細。',
        },
        {
          title: '依照目的建立專案和預算',
          desc: '對於金錢的使用目的提供了更進階的分類方式，像是生活開銷、旅遊支出、投資理財、家庭用途等等，方便你建立報表去查看統計分析，還支援預算分配功能，讓你可以提前規劃和控制支出。',
        },
        {
          title: '一步步養成記帳的習慣',
          desc: '養成習慣的首要條件就是強迫自己每次消費後就馬上記錄。MOZE 提供了每日記帳提醒，也支援 Widget 和 Apple Watch 來提升記帳的便利性。當你堅持一個月後再來檢視報表，你將會更加清楚你自己的消費行為。',
        },
      ],
    },
    'section-senior': {
      title: '有記帳經驗的老手',
      features: [
        {
          title: '一般記帳功能已經滿足不了你',
          desc: '重複的週期記錄、分期付款、幫人代付、借還款追蹤、待繳帳款、延後入帳、信用卡對帳等更多進階的功能等你挖掘。',
        },
        {
          title: '整合你的舊有記錄',
          desc: '如果你的舊有記錄支援轉成 CSV 檔，那你可以參考 MOZE 的匯入說明把資料整理成新的格式來進行匯入，統整你過去的歷史帳目。',
        },
        {
          title: '多幣種支援',
          desc: '常感受到外幣記帳很麻煩嗎？MOZE 支援帳戶跨幣種記錄，如果你需要以外幣進行支付，不需要考慮匯率問題，單單記錄下外幣金額，MOZE 將會自動幫你轉換成主幣種的對應金額。等到收到帳單後，再去調整成實際的匯率即可。',
        },
        {
          title: '創建各類報表',
          desc: '你可以篩選條件去建立你想查看的報表，例如一萬塊以上的大宗花費年度報表。報表提供明細、各類別收支的比例，以及開銷 TOP 10 等圖表分析，讓你更加瞭解自己的花費習慣，進而減少不必要的支出。',
        },
      ],
    },
  },
  'section-4': {
    title: '特色功能',
    paragraph: '我們一直持續地聆聽使用者的回饋和建議，不斷的優化設計和開發新功能，希望可以提供更好的記帳體驗給你',
    features: [
      '多帳戶支援',
      '跨幣種記帳',
      '借還款追蹤',
      '專案分組',
      '預算規劃',
      '重覆性收支',
      '分期付款',
      '待繳帳款',
      '延後入帳',
      '帳目複製',
      '退款',
      '拆帳',
      '手續費／折扣',
      '信用卡繳費提醒',
      '信用額度共用',
      '對賬模式',
      '自動儲值',
      '豐富的報表分析',
      'CSV 匯入/匯出',
      '搜尋',
      '批次修改/刪除',
      '餘額調整',
      '客製化類別',
      '密碼保護',
      '雲端備份還原',
      '多種主題',
      '免費銀行圖示下載',
      '手機條碼載具',
      '電子發票掃描',
      '發票自動對獎',
      '發票歸帳',
      'Widget 小工具',
      'Apple Watch 記帳',
      '快速的客服回覆',
    ],
    more: '更多功能介紹',
  },
  'section-5': [{
    title: '螢幕快照',
    paragraph: 'MOZE 不單單只是一套記帳工具，我們為它添加入了些許美感和質感，希望能夠為你的記帳生活添增一點樂趣',
  }, {
    title: 'Widget 小工具',
    paragraph: '支援快速記帳、帳戶總覽、預算總覽、事件提醒和手機條碼',
  }, {
    title: 'Apple Watch',
    paragraph: '支援快速記帳、帳戶總覽、專案預算、近期記錄和手機條碼（WatchOS 4+）',
  }],
};
