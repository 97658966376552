export default {
  'title': 'MOZE - Simplify Your Financial Life',
  'nav': {
    'home': 'HOME',
    'features': 'FEATURES',
    'pricing': 'PRICING',
    'faq': 'FAQ'
  },
  'section-1': {
    'title': 'Simplify Your Financial Life',
    'paragraph': 'MOZE is a personal expense tracker which has a beautiful interface and focus on user-experience. We want all of you to build a habit of tracking your daily expense.',
    'btn-free-download': 'DOWNLOAD',
    'btn-coming-soon': 'COMING SOON',
  },
  'section-2': {
    title: 'Tracking is just the beginning of your financial management',
    paragraph: 'Everyone should know where money comes in and goes out',
    features: {
      grasp: {
        title: 'Keep track of your money',
        desc: 'Tracking every expenses you have and understand your spending pattern',
      },
      planning: {
        title: 'Easily manage your budget',
        desc: 'Creating the personalised budgets according to your preference. Allowing you have a more effective financial management.',
      },
      economize: {
        title: 'Reduce the unnecessary expense',
        desc: 'Building a good spending habit by identifying the needs and wants',
      },
      goal: {
        title: 'Reach your financial goals',
        desc: 'Keeping your every accounts and balances in one place to understand your financial life',
      },
    },
  },
  'section-3': {
    'title': 'Have you ever used any expense tracker before?',
    'paragraph': 'Don’t worry, no matter you are new to expense tracking or experienced. MOZE provides a comprehensive workflow and advanced features for a better expense tracking experience.',
    'section-newbie': {
      title: 'For New User',
      features: [
        {
          title: 'Putting your expenses into different categories',
          desc: 'MOZE has over 100 icons built-in for categorisation. You can simply complete a record by choosing the right category (e.g. drink, dinner or parking fee), put in the amount and choose the payment account.',
        },
        {
          title: 'Keeping an eye on all your assets and debts',
          desc: 'You can create different accounts in MOZE for your credit cards, bank accounts, cash, foreign currencies, vouchers and other things you can think of. Putting all your accounts in one place allows you to have a better understanding of your financial situation.',
        },
        {
          title: 'Creating different projects and budgets',
          desc: 'In MOZE you are also able to create different project for suiting different goals, for example tracking your daily expense, travelling expense or family expense. The charts are also available for giving you a better view. The projects are also allowing user to apply the budgets to control your spending.',
        },
        {
          title: 'Building an expense tracking habit step-by-step',
          desc: 'For building this habit, you have to create the record right after any purchase. MOZE has a daily reminder for you , as well as widgets and Apple Watch support, which makes the process easier. We truly believe our user will feel rewarded after seeing their monthly report showing where their money goes.',
        },
      ],
    },
    'section-senior': {
      title: 'For Experienced User',
      features: [
        {
          title: 'Looking for more advanced features',
          desc: 'Recurring transactions, installments, receivables, payables, pending transactions, postponed transactions, statement mode, there are so many advance features for you to discover.',
        },
        {
          title: 'Integrating with your existing records',
          desc: 'You can simply export your existing records as CSV file, adjust the format to meet the standard of the CSV Example in MOZE, then import all your data to MOZE.',
        },
        {
          title: 'Multi-currency support',
          desc: 'Have you ever been annoyed when tracking an expense made in a foreign currency? MOZE supports multi-currency tracking. If the transaction is made in foreign currency, you can simply record the amount in that currency, MOZE will do the conversion for you automatically. You can also adjust the exchange rate after receiving the statement.',
        },
        {
          title: 'Creating different kinds of reports',
          desc: 'You can creating different reports by applying different conditions to suit your needs. For example, you can set a report of big purchases by setting the minimum amount of $10,000. In the reports, you can view the proportion of different categories, top expense rankings and many other analysis. This feature helps you to understand how you spend your money and try to reduce any unnecessary expenses.',
        },
      ],
    },
  },
  'section-4': {
    title: 'FEATURES',
    paragraph: 'We are always listening to any feedbacks and suggestions from our users, trying to make improvements and developing more new features. We want to provide a much better experience for all of you.',
    features: [
      'Multiple Accounts',
      'Multi-Currency',
      'Payables & Receivables',
      'Projects',
      'Budget Planning',
      'Recurring Transactions',
      'Installments',
      'Transactions Reminder',
      'Postpone Transaction',
      'Transaction Duplication',
      'Refund',
      'Split-Payment',
      'Fee / Discount',
      'Payment Reminder',
      'Credit Limit Sharing',
      'Statement Mode',
      'Auto-Deposit',
      'Rich Charts & Analysis',
      'CSV Import / Export',
      'Search',
      'Batch Edit / Delete',
      'Balance Adjustment',
      'Customize Categories',
      'Passcode Protection',
      'Cloud Backup / Restore',
      'Themes',
      'Free Bank Icons',
      'Widgets',
      'Apple Watch',
      'Customer Service',
    ],
    more: 'Learn more',
  },
  'section-5': [{
    title: 'SCREENSHOTS',
    paragraph: 'MOZE is beyond an expense tracking tool, we have added a bit of esthetic to it and hoping to bring more fun to your life.',
  }, {
    title: 'Widgets',
    paragraph: 'Support fast expense tracking, account overview, budget overview and payment reminder.',
  }, {
    title: 'Apple Watch',
    paragraph: 'Support fast expense tracking, account overview, budget overview and recent transactions. (WatchOS 4+)',
  }],
};
