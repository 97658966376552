/**
 * 繁體中文翻譯
 */
export default {
  '$utils': require('./utils').default,
  base: require('./base').default,
  footer: require('./footer').default,
  home: require('./home').default,
  faq: require('./faq').default,
  pricing: require('./pricing').default,
  features: require('./features').default,
  'winning-list': require('./winning-list').default,
  ...require(`element-ui/lib/locale/lang/zh-TW`).default,
};
