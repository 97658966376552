export default {
  product: {
    name: 'PRODUCT',
    items: {
      feature: 'FEATURES',
      download: 'DOWNLOAD',
      pricing: 'PRICING',
      faq: 'FAQ',
    },
  },
  support: {
    name: 'SUPPORT',
    items: {
      'csv-import-example': 'CSV TEMPLATE',
      'press-kit': 'PRESS KIT',
      'contactus': 'CONTACT US',
    },
  },
  social: {
    name: 'SOCIAL',
    items: {
      facebook: 'FACEBOOK',
      twitter: 'TWITTER',
      telegram: 'TELEGRAM',
      weibo: 'WEIBO',
    },
  },
  legal: {
    name: 'LEGAL',
    items: {
      privacy: 'PRIVACY POLICY',
      terms: 'TERMS OF SERVICE',
    },
  },
};
