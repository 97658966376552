import Vue from 'vue';
import Router from 'vue-router';
import HomeRoute from '@/routes/root';

Vue.use(Router);

export default () => {
  const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
      ...HomeRoute,
    ],
    scrollBehavior() {
      return { x: 0, y: 0 };
    },
  });
  return router;
};
