export default {
  'title': 'MOZE - 最美记帐',
  'nav': {
    'home': '首页',
    'features': '功能介绍',
    'pricing': '定价方案',
    'faq': '常见问题',
  },
  'section-1': {
    'title': '最美记帐',
    'paragraph': 'MOZE 是一款个人记账应用，拥有精美的界面及舒适的用户体验，致力于将记账变成你的生活习惯。 ',
    'btn-free-download': '免费下载',
    'btn-coming-soon': '即将上线',
  },
  'section-2': {
    title: '记帐，是理财的第一步',
    paragraph: '每个人都应该去尝试看看，了解你自己平常的花费习惯，找出为什么身上的钱总是留不住的原因',
    features: {
      grasp: {
        title: '清楚掌握收支',
        desc: '记录每一笔资金流水，了解你每一天的生活',
      },
      planning: {
        title: '做好预算规划',
        desc: '找出你的固定支出和浮动支出，更有效地规划财务并且掌控消费进度',
      },
      economize: {
        title: '节省非需开支',
        desc: '购买昂贵大件时先自问是真正需要还是简单的想要，帮助你建立良好的消费习惯',
      },
      goal: {
        title: '提高目标意识',
        desc: '拥有健康的理财观念，才是拥有财富的基础',
      },
    },
  },
  'section-3': {
    'title': '先前有过记帐经验吗？ ',
    'paragraph': '别担心，不管你是新手还是老手，MOZE 提供了完整的记账流程和细致的高阶功能来帮助你得到更优雅的记账体验',
    'section-newbie': {
      title: '刚接触记帐的新手',
      features: [
        {
          title: '为你的消费分门别类',
          desc: 'MOZE 内置了超过100 种生活中常见的收支分类图标，你只要选择适合的类别（比如饮料、晚餐、停车费），填上金额和所选支付账户，即可轻松完成一笔记账。 ',
        },
        {
          title: '掌控你的资产',
          desc: '将你的各张信用卡、不同银行存款、现金、外币、礼品卡等各式各样的资产和负债，个别建立账户去掌控收支明细。 ',
        },
        {
          title: '根据目的建立项目和预算',
          desc: '对于金钱的使用目的提供了更高阶的分类方式，像是生活开销、旅游支出、投资理财、家庭用途等等，方便你建立报表去查看统计分析，还支持预算分配功能，让你可以提前规划和控制支出。 ',
        },
        {
          title: '一步步养成记帐的习惯',
          desc: '养成习惯的首要条件就是强迫自己每次消费后就马上记录。 MOZE 提供了每日记账提醒，也支持 Widget 和 Apple Watch 来提升记账的便利性。当你坚持一个月后再检查报表，你将会更加清楚你自己的消费行为。 ',
        },
      ],
    },
    'section-senior': {
      title: '有记帐经验的老手',
      features: [
        {
          title: '一般记账应用的功能已经满足不了你',
          desc: '重复的周期记录、分期付款、帮人代付、借还款追踪、待缴账款、延后入账、信用卡对账等更多高阶的功能等你发掘。 ',
        },
        {
          title: '整合你之前的历史记账记录',
          desc: '如果你将之前的历史记账记录支持转成 CSV 文件，那你可以参考 MOZE 的导入说明把资料整理成新的格式来进行导入，整合你过去的历史账单。 ',
        },
        {
          title: '多币种支持',
          desc: '常感受到外币记账很麻烦吗？ MOZE 支持账户跨币种记录，如果你需要以外币进行支付，不需要考虑汇率问题，只要记录下外币金额，MOZE 将会自动帮你转换成主币种的对应金额。等到收到账单后，再去调整成实际的汇率即可。 ',
        },
        {
          title: '创建各类报表',
          desc: '你可以筛选条件去建立你想查看的报表，例如一万块以上的大额花费年度报表。报表提供明细、各类别收支的比例，以及开销 TOP 10 等图表分析，让你更加了解自己的花费习惯，从而减少不必要的支出。 ',
        },
      ],
    },
  },
  'section-4': {
    title: '特色功能',
    paragraph: '我们一直持续地聆听 MOZE 用户的回馈和建议，不断的优化体验和开发新功能，希望可以提供更好的记账体验给你',
    features: [
      '多账户支持',
      '跨币种记账',
      '借还款监控',
      '项目分组',
      '预算规划',
      '重复性收支',
      '分期付款',
      '未还账单',
      '延后入账',
      '账单复制',
      '退款',
      '拆账',
      '手续费／折扣',
      '信用卡还款提醒',
      '信用额度共用',
      '对账模式',
      '自动储值',
      '丰富的报表分析',
      'CSV 导入/导出',
      '搜索',
      '批次修改/删除',
      '余额调整',
      '定制化类别',
      '密码保护',
      '云端备份还原',
      '多主题',
      '免费银行图标下载',
      'Widget 小工具',
      'Apple Watch 记账',
      '优先得到开发者回复',
    ],
    more: '更多功能介绍',
  },
  'section-5': [{
    title: '萤幕快照',
    paragraph: 'MOZE 不单单只是一套记账工具，我们为它添加入了些许美感和质感，希望能够为你的记账生活添增一点乐趣',
  }, {
    title: 'Widget 小工具',
    paragraph: '支援快速记帐、帐户总览、预算总览和事件提醒',
  }, {
    title: 'Apple Watch',
    paragraph: '支援快速记帐、帐户总览、项目预算和近期记录（WatchOS 4+）',
  }],
};
