export default {
  product: {
    name: '产品',
    items: {
      feature: '功能介绍',
      download: '下载',
      pricing: '定价方案',
      faq: '常见问题',
    },
  },
  support: {
    name: '支持',
    items: {
      'csv-import-example': 'CSV 导入范例',
      'press-kit': 'PRESS KIT',
      'contactus': '联络我们',
    },
  },
  social: {
    name: '社群',
    items: {
      facebook: 'FACEBOOK',
      twitter: 'TWITTER',
      telegram: 'TELEGRAM',
      weibo: '微博',
    },
  },
  legal: {
    name: '服务条款',
    items: {
      privacy: '隐私权政策',
      terms: '使用条款',
    },
  },
};
