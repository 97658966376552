export default {
  title: '定價方案',
  plans: {
    free: 'FREE',
    premium: 'PREMIUM',
  },
  suitable: {
    title: '適合對象',
    free: '剛接觸記帳的新手',
    premium: '有記帳經驗的好手',
  },
  price: {
    'title': '定價',
    'free': 'NT$0',
    'premium': 'NT$30',
    'premium-year': 'NT$300',
  },
  desc: {
    'free': '基本功能全部免費',
    'premium': '每月訂閱，可隨時取消',
    'premium-year': '每年訂閱，費用直接折抵 2 個月，可隨時取消',
  },
  trial: {
    premium: '14天免費試用所有功能',
  },
  functions: {
    'base-accounting': '基本記帳',
    'unlimited-amount': '無限筆數',
    'expenses': '支出',
    'income': '收入',
    'transfer': '轉帳',
    'clone-record': '複製記錄',

    'advance-accounting': '進階記帳',
    'corss-currency': '跨幣種記帳',
    'receivable': '應收款項',
    'payable': '應付款項',
    'extension': '延後入帳',
    'notification': '提醒入帳',
    'repeat': '重複性收支',
    'installment': '分期付款',
    'refund': '退款',
    'debit': '拆帳',
    'procedure-fee': '手續費與折扣',

    'base-account': '基本帳戶',
    'unlimited-account': '無限帳戶創建',
    'all-currency-summary': '各幣種統計',
    'detail-and-statistics': '交易明細和統計',
    'credit-account': '信用帳戶',
    'modify-balance': '餘額調整',
    'pend-fee-notification': '待繳帳款提醒',

    'advance-account': '進階帳戶',
    'reconciliation-mode': '對帳模式',
    'auto-deposit': '自動儲值',
    'share-credit': '信用額度共用',

    'base-report': '基本報表',
    'monthly-summary': '每月總覽統計',
    'monthly-transaction-detail': '每月交易明細',
    'monthly-category-distributed': '每月類別分佈',
    'monthly-transaction-rank': '每月交易排行',
    'monthly-name-summary': '每月名稱統計',
    'monthly-merchant-summary': '每月商家統計',
    'monthly-tag-summary': '每月標籤統計',

    'advance-report': '進階報表',
    'unlimited-report': '無限報表創建',
    'free-filter': '自由設定篩選條件',
    'report-export-csv': '報表匯出CSV',

    'project-group': '專案分組',

    'budget-planning': '預算規劃',
    'project-budget': '專案預算',
    'main-category-budget': '主類別預算',
    'sub-category-budget': '子類別預算',

    'password-protect': '密碼保護',
    'cloud-backup-restore': '雲端備份還原',
    'category-manager': '類別管理',
    'classic-dark-theme': '經典深色主題',
    'other-theme': '其他經典主題',

    'einvoice': '電子發票',
    'invoice-scanner': '發票掃描',
    'download-einvoice': '載具發票下載',
    'auto-lotto': '自動對獎',

    'csv-export-import': 'CSV 匯入／匯出',
    'search': '搜尋',
    'batch-modify-delete': '批次修改／刪除',
    'widget': 'Widget 小工具',
    'apple-watch': 'Apple Watch',
    'email-support': 'Email 支援服務',
    'new-features': '優先支援最新功能',
  },
  tips: {
    'corss-currency': '如果你的帳戶支援多個幣種，我們會提供即時的線上匯率給你參考，你也可以自己手動調整匯率來符合實際扣款的金額。',
    'receivable': '針對借錢給別人、幫朋友代刷信用卡或是公司報帳等情況，你可以新增一筆應收款項來追蹤這筆帳款。',
    'payable': '針對跟別人借錢、跟銀行貸款等情況，你可以新增一筆應付款項來追蹤你的還款記錄。',
    'extension': '信用卡支付常常會遇到消費日跟入帳日不同的狀況，現在你可以將入帳方式改成延後入帳，並填寫實際入帳的日期。',
    'notification': '針對尚未支付的款項，例如交通罰單，你可以新增一筆支出，把入帳方式改成提醒入帳，等日期發生時就會提醒你有一筆待完成的交易。',
    'repeat': '有些固定的週期性收支例如每個月的薪資，或是每個月的電話費，你可以透過週期事件來自動產生對應的記錄。',
    'installment': '針對分期付款我們提供了許多彈性的設定，你可以自定總分期金額、分期期數、每期金額，也可以設定分期是否為整數，或是分期除不盡的餘額要納入首期或是末期等等。',
    'refund': '當你退刷信用卡，或是退款某件商品時，可以在原先的記錄上點開，右上角的按鈕選單中有一個退款按鈕可以把這筆支出抵銷掉。',
    'debit': '如果同一筆消費內有多個品項，例如去超市採購，你可以將每個品項分門別類記錄在同一筆帳上。',
    'reconciliation-mode': '當你收到信用卡帳單時，可以經由對帳模式清楚知道每一筆支出的費用是否與帳單一致。',
    'auto-deposit': '當這個帳戶的餘額低於你設定的金額，會自動從你設定好的儲值來源帳戶轉入一筆固定金額。',
    'share-credit': '如果同一家銀行有申辦多張信用卡，MOZE 會自動幫你計算這些信用卡帳戶的可用額度。',
    'password-protect': '支援手動輸入密碼、TouchID、FaceID。',
    'cloud-backup-restore': '你可以備份至 Dropbox / iCloud，所有資料只會存放在你自己的雲端上。',
  },
};
