export default {
  title: '定价方案',
  plans: {
    free: 'FREE',
    premium: 'PREMIUM',
  },
  suitable: {
    title: '适合对象',
    free: '刚接触记帐的新手',
    premium: '有记帐经验的好手',
  },
  price: {
    'title': '定价',
    'free': '¥0',
    'premium': '¥7.00',
    'premium-year': '¥70.00',
  },
  desc: {
    'free': '基本功能全部免费',
    'premium': '每月订阅，可随时取消',
    'premium-year': '每年订阅，费用直接折抵 2 个月，可随时取消',
  },
  trial: {
    premium: '14天免费试用所有功能',
  },
  functions: {
    'base-accounting': '基本记帐',
    'unlimited-amount': '无限笔数',
    'expenses': '支出',
    'income': '收入',
    'transfer': '转帐',
    'clone-record': '复制记录',

    'advance-accounting': '进阶记帐',
    'corss-currency': '跨币种记帐',
    'receivable': '应收款项',
    'payable': '应付款项',
    'extension': '延后入帐',
    'notification': '提醒入帐',
    'repeat': '重复性收支',
    'installment': '分期付款',
    'refund': '退款',
    'debit': '拆帐',
    'procedure-fee': '手续费与折扣',

    'base-account': '基本帐户',
    'unlimited-account': '无限帐户创建',
    'all-currency-summary': '各币种统计',
    'detail-and-statistics': '交易明细和统计',
    'credit-account': '信用帐户',
    'modify-balance': '余额调整',
    'pend-fee-notification': '待缴帐款提醒',

    'advance-account': '进阶帐户',
    'reconciliation-mode': '对帐模式',
    'auto-deposit': '自动储值',
    'share-credit': '信用额度共用',

    'base-report': '基本报表',
    'monthly-summary': '每月总览统计',
    'monthly-transaction-detail': '每月交易明细',
    'monthly-category-distributed': '每月类别分布',
    'monthly-transaction-rank': '每月交易排行',
    'monthly-name-summary': '每月名称统计',
    'monthly-merchant-summary': '每月商家统计',
    'monthly-tag-summary': '每月标签统计',

    'advance-report': '进阶报表',
    'unlimited-report': '无限报表创建',
    'free-filter': '自由设定筛选条件',
    'report-export-csv': '报表导出CSV',

    'project-group': '项目分组',

    'budget-planning': '预算规划',
    'project-budget': '项目预算',
    'main-category-budget': '主类别预算',
    'sub-category-budget': '子类别预算',

    'password-protect': '密码保护',
    'cloud-backup-restore': '云端备份还原',
    'category-manager': '类别管理',
    'classic-dark-theme': '经典深色主题',
    'other-theme': '其他经典主题',

    'csv-export-import': 'CSV 导入／导出',
    'search': '搜寻',
    'batch-modify-delete': '批次修改／删除',
    'widget': 'Widget 小工具',
    'apple-watch': 'Apple Watch',
    'email-support': 'Email 支持服务',
    'new-features': '优先支援最新功能',
  },
  tips: {
    'corss-currency': '如果你的帐户支援多个币种，我们会提供即时的线上汇率给你参考，你也可以自己手动调整汇率来符合实际扣款的金额。',
    'receivable': '针对借钱给别人、帮朋友代刷信用卡或是公司报帐等情况，你可以新增一笔应收款项来追踪这笔帐款。',
    'payable': '针对跟别人借钱、跟银行贷款等情况，你可以新增一笔应付款项来追踪你的还款记录。',
    'extension': '信用卡支付常常会遇到消费日跟入帐日不同的状况，现在你可以将入帐方式改成延后入帐，并填写实际入帐的日期。',
    'notification': '针对尚未支付的款项，例如交通罚单，你可以新增一笔支出，把入帐方式改成提醒入帐，等日期发生时就会提醒你有一笔待完成的交易。',
    'repeat': '有些固定的周期性收支例如每个月的薪资，或是每个月的电话费，你可以透过周期事件来自动产生对应的记录。',
    'installment': '针对分期付款我们提供了许多弹性的设定，你可以自定总分期金额、分期期数、每期金额，也可以设定分期是否为整数，或是分期除不尽的余额要纳入首期或是末期等等。',
    'refund': '当你退刷信用卡，或是退款某件商品时，可以在原先的记录上点开，右上角的按钮选单中有一个退款按钮可以把这笔支出抵销掉。',
    'debit': '如果同一笔消费内有多个品项，例如去超市采购，你可以将每个品项分门别类记录在同一笔帐上。',
    'reconciliation-mode': '当你收到信用卡帐单时，可以经由对帐模式清楚知道每一笔支出的费用是否与帐单一致。',
    'auto-deposit': '当这个帐户的余额低于你设定的金额，会自动从你设定好的储值来源帐户转入一笔固定金额。',
    'share-credit': '如果同一家银行有申办多张信用卡，MOZE 会自动帮你计算这些信用卡帐户的可用额度。',
    'password-protect': '支援手动输入密码、TouchID、FaceID。',
    'cloud-backup-restore': '你可以备份至 Dropbox / iCloud，所有资料只会存放在你自己的云端上。',
  },
};

