import Vue from 'vue';
import App from './App.vue';
import createRouter from './router';
import createStore from './store';
import createI18n from './i18n';

import '@uvue/core/vueclass';
import '@/plugins/vue-meta';
import '@/plugins/element-ui';
import '@/plugins/fontawesome';
import '@/plugins/markdown';

Vue.config.productionTip = false;

export default () => {
  const store = createStore();
  const router = createRouter();
  const i18n = createI18n();

  const app = new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
  });

  return app;
};
