export default {
  title: '功能介绍',
  categories: {
    'tracking-the-expense': {
      name: '记帐',
      files: {
        'introduction': '介绍',
        'multi-currency-support': '跨币种记录',
        'fee-and-discount': '手续费与折扣',
        'transaction-reminder': '提醒入帐',
        'postpone-transaction': '延后入帐',
        'recurring-event': '周期性收支',
        'installment': '分期付款',
        'transfer': '转帐',
        'receivables': '应收款项',
        'payables': '应付款项',
        'refund': '退款',
        'complete-gesture': '完成记帐手势',
        'collection': '拆帐',
        'continue-recording': '连续记帐',
        'template': '模板',
      },
    },

    'account': {
      name: '帐户',
      files: {
        'introduction': '介绍',
        'credit-account': '信用帐户',
        'credit-sharing': '信用额度共用',
        'statement-interval': '结算区间',
        'auto-deposit': '自动储值',
        'balance-included': '纳入总余额',
        'receivables-payables': '应收应付款项',
        'statement-mode': '对帐模式',
        'adjust-balance': '餘額調整',
        'statement-payment': '信用卡缴款',
        'combine-account': '信用卡账单合并',
        'statement-installment': '信用卡账单分期',
        'bonus-rewards': '返利回饋',
      },
    },

    'project': {
      name: '专案',
      files: {
        'introduction': '介绍',
        'statistic-project': '统计专案',
        'budget': '预算',
      },
    },

    'report': {
      name: '报表',
      files: {
        introduction: '介绍',
      },
    },

    'notifications': {
      name: '通知中心',
      files: {
        introduction: '介绍',
      },
    },

    'search': {
      name: '搜寻',
      files: {
        'introduction': '介绍',
        'batch-edit-and-delete': '批次修改删除',
      },
    },

    'backup-restore': {
      name: '备份＆还原',
      files: {
        introduction: '介绍',
      },
    },

    'import-export': {
      name: '汇入＆汇出',
      files: {
        import: '汇入',
        export: '汇出',
      },
    },

    'e-invoice': {
      name: '电子发票（台湾限定）',
      files: {
        'introduction': '介绍',
        'carrier': '手机条码载具',
        'invoice-link': '发票归帐',
        'winning-list': '自动对奖',
      },
    },
  },
};
