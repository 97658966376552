import _ from 'lodash';
import VueI18n from 'vue-i18n';

export class LocationService {
  public static provides = [{
    key: 'tw',
    codes: ['zh-tw'],
  }, {
    key: 'cn',
    codes: ['zh-cn'],
  }, {
    key: 'en',
    codes: ['en-us', 'en-gb', 'en-au', 'en-ca', 'en-nz', 'en-ie', 'en-za', 'en-jm', 'en', 'en-bz', 'en-tt'],
  }];

  public static toHtmlLang(code: string): string | null {
    const locale = _.find(this.provides, ['key', code]);
    if (!locale) return null;

    switch (locale.key) {
      case 'cn':
        return 'zh-Hans';
      case 'en':
        return 'en';
      case 'tw':
      default:
        return 'zh-Hant';
    }
  }

  public static getUserLanguage(raw: boolean = false): string | null {
    if (typeof window === 'undefined') { return null; }

    let code: string = window.navigator.language || (window.navigator as any).browserLanguage ;
    code = code.toLowerCase();

    if (raw === true) {
      return code;
    }

    for (let index = 0; index < this.provides.length; index++) {
      const provide = this.provides[index];
      if (provide.codes.includes(code)) {
        return provide.key;
      }
    }

    return null;
  }

  public loaded: string[] = [];
  private _i18n: VueI18n;

  constructor(i18n: VueI18n) {
    this._i18n = i18n;

    _.forEach(i18n.messages, (messages, lang) => {
      if (this.isLoaded(lang)) {
        return;
      }
      this.loaded.push(lang);
    });
  }

  public async load(lang: string): Promise<string> {
    if (this._i18n.locale === lang && this.isLoaded(lang)) {
      return lang;
    }

    if (this.isLoaded(lang)) {
      return this.change(lang);
    }

    const messages = await import(`@/locales/${lang}`);
    this._i18n.setLocaleMessage(lang, messages.default);
    this.loaded.push(lang);
    return this.change(lang);
  }

  public change(lang: string): string {
    this._i18n.locale = lang;

    return lang;
  }

  public isLoaded(lang: string): boolean {
    return _.includes(this.loaded, lang);
  }
}
