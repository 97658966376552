export default {
  title: 'PRICING',
  plans: {
    free: 'FREE',
    premium: 'PREMIUM',
  },
  suitable: {
    title: 'Target',
    free: 'For New User',
    premium: 'For Experienced User',
  },
  price: {
    'title': 'Price',
    'free': '$0',
    'premium': '$0.99',
    'premium-year': '$9.99',
  },
  desc: {
    'free': 'Free for all basic features',
    'premium': 'Monthly subscription, cancel at any time.',
    'premium-year': 'Annual subscription with 2 months free. Cancel at any time.',
  },
  trial: {
    premium: '14 Days Free Trial',
  },
  functions: {
    'base-accounting': 'Basic Tracking',
    'unlimited-amount': 'Unlimited Records',
    'expenses': 'Expense',
    'income': 'Income',
    'transfer': 'Transfer',
    'clone-record': 'Transaction Clone',

    'advance-accounting': 'Advanced Tracking',
    'corss-currency': 'Multi-Currency',
    'receivable': 'Receivables',
    'payable': 'Payables',
    'extension': 'Postpone Transaction',
    'notification': 'Transaction Reminder',
    'repeat': 'Recurring Event',
    'installment': 'Installment',
    'refund': 'Refund',
    'debit': 'Spilt Payment',
    'procedure-fee': 'Fee & Discount',

    'base-account': 'Basic Accounts',
    'unlimited-account': 'Unlimited Accounts',
    'all-currency-summary': 'Multi-Currencies Statistics',
    'detail-and-statistics': 'Transaction History',
    'credit-account': 'Credit Account',
    'modify-balance': 'Balance Adjustment',
    'pend-fee-notification': 'Awaiting Payment Reminder',

    'advance-account': 'Advanced account',
    'reconciliation-mode': 'Statement Mode',
    'auto-deposit': 'Auto-Deposit',
    'share-credit': 'Credit Limit Sharing',

    'base-report': 'Basic Report',
    'monthly-summary': 'Monthly Report',
    'monthly-transaction-detail': 'Transaction History',
    'monthly-category-distributed': 'Category Distribution',
    'monthly-transaction-rank': 'Top Expenses',
    'monthly-name-summary': 'Names Rankings',
    'monthly-merchant-summary': 'Stores Rankings',
    'monthly-tag-summary': 'Tags Rankings',

    'advance-report': 'Advanced Reports',
    'unlimited-report': 'Unlimited Reports',
    'free-filter': 'Various Conditions Filtering',
    'report-export-csv': 'CSV Export',

    'project-group': 'Project',

    'budget-planning': 'Budget Planning',
    'project-budget': 'Project Budget',
    'main-category-budget': 'Category Budget',
    'sub-category-budget': 'Subcategory Budget',

    'password-protect': 'Passcode Protection',
    'cloud-backup-restore': 'Cloud Backup / Restore',
    'category-manager': 'Category Management',
    'classic-dark-theme': 'Classic Dark Theme',
    'other-theme': 'Other Classic Theme',

    'csv-export-import': 'CSV Import / Export',
    'search': 'Search',
    'batch-modify-delete': 'Batch Edit / Delete',
    'widget': 'Widgets',
    'apple-watch': 'Apple Watch',
    'email-support': 'Email Supprt',
    'new-features': 'Getting New Features Early',
  },
  tips: {
    'corss-currency': 'If you have accounts with foreign currencies, we will provide the online exchange rate for your reference.',
    'receivable': 'Tracks all the money lend to someone or any reminburse.',
    'payable': 'Tracks all your personal loan and mortgage.',
    'extension': 'When using a credit card, there will always be a slight delay before your expense appears in your credit card statement. Postpone transaction ensures you are paying a right amount to settle your credit card bills.',
    'notification': 'You can add the reminder on any unsettled payments.',
    'repeat': 'You can simply set up a recurring event base on the frequency, like monthly salary and phone bill.',
    'installment': 'MOZE offers a great flexibility for tracking installments. You can customise your own installment plan like the total amount, repayment period and monthly repayment amount.',
    'refund': 'When you have a refund after any purchase, you can simply tap the “Refund” button on the original record to offset each other.',
    'debit': 'If your expense is containing multiple categories, just like groceries shopping, you can split the expenses into different categories in one place.',
    'reconciliation-mode': 'When you receive your credit card bill, you can use statement mode to review every expense.',
    'auto-deposit': 'When the balance of the account is running low, you can choose to make a transfer from the designated account automatically.',
    'share-credit': 'When you have several credit cards from the same bank and they share the same credit limit, MOZE supports sharing limit by different credit cards.',
    'password-protect': 'Support Passcode, TouchID and FaceID',
    'cloud-backup-restore': 'Support Dropbox / iCloud backup services',
  },
};
