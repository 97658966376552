






import { forEach } from 'lodash';
import { Vue, Component } from 'vue-property-decorator';

@Component({
  mounted() {
    // 外部連結開新分頁
    const $externalLinks = this.$el.querySelectorAll('a[href^="http"]');
    forEach($externalLinks, ($el) => {
      $el.setAttribute('target', '_blank');
    });
  },
})
export default class Markdown extends Vue {

}
