export default {
  product: {
    name: '產品',
    items: {
      feature: '功能介紹',
      download: '下載',
      pricing: '定價方案',
      faq: '常見問題',
    },
  },
  support: {
    name: '支援',
    items: {
      'csv-import-example': 'CSV 匯入範例',
      'winning-list': '統一發票中獎號碼',
      'press-kit': 'PRESS KIT',
      'contactus': '聯絡我們',
    },
  },
  social: {
    name: '社群',
    items: {
      facebook: 'FACEBOOK',
      twitter: 'TWITTER',
      telegram: 'TELEGRAM',
      weibo: '微博',
    },
  },
  legal: {
    name: '服務條款',
    items: {
      privacy: '隱私權政策',
      terms: '使用條款',
    },
  },
};
