import 'normalize.css';
import { Vue, Component } from 'vue-property-decorator';
import { Title } from '@/utils/seo/title';
import { LocationService } from '@/services/location';

@Component({
  head() {
    const self = this as App;
    const lang = LocationService.toHtmlLang(self.$i18n.locale);

    return {
      titleTemplate: Title.template,
      htmlAttrs: {
        lang: lang || '',
      },
      meta: [{
        charset: 'utf-8',
      }, {
        name: 'google-site-verification',
        content: 'InJ3frLsDm4ay1z_MDT0_isteNa3063Tl98nR0Xgd2U',
      }, {
        name: 'viewport',
        content: 'width=device-width,initial-scale=1.0',
      }],
    };
  },
  beforeCreate() {
    const self = this as App;
    self.locationService = new LocationService(self.$i18n);
  },
})
export default class App extends Vue {
  locationService!: LocationService;
}
