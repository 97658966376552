import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default () => {
  const store = new Vuex.Store({
    state: () => ({}),
    mutations: {},
    actions: {},
  });
  return store;
};
